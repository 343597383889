import PropTypes from "prop-types";
import React from "react";
import { Dropdown } from "../../../common/components/dropdown/Dropdown";
import View from "../../views/View";

export function ViewSelector({ views, loading, current, handleBindToView }) {
	const defaultView = current?.name || views[0].value;
	const dropdownToggle = !loading ? defaultView : "Loading...";

	return (
		<Dropdown
			dropdownToggle={dropdownToggle}
			dropdownItems={views}
			preselected={true}
			onDropdownItemClick={(id) => handleBindToView(id)}
			dropdownKey="view_selector"
		/>
	);
}

ViewSelector.propTypes = {
	views: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	current: PropTypes.instanceOf(View),
	handleBindToView: PropTypes.func.isRequired,
};
