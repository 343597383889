import { useDispatch } from "react-redux";
import { updateSearchParameters } from "../../../common/hash/actions";

export default function usePaging(paging) {
	const dispatch = useDispatch();
	const currentPage = paging?.get("page", 0);
	const pages = paging?.get("pages", 0);
	const pageSize = paging?.get("pageSize", 0);
	const results = paging?.get("results", 0);
	const resultsUnfiltered = paging?.get("resultsUnfiltered", 0);

	const onPageSelected = (page) => {
		dispatch(updateSearchParameters({ page }));
	};

	return {
		currentPage,
		pageSize,
		results,
		resultsUnfiltered,
		pages,
		onPageSelected,
	};
}
