import PropTypes from "prop-types";
import React from "react";
import { Dropdown } from "../../../../common/components/dropdown/Dropdown";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import {
	dropdownCriterionChoicesType,
	requiredRolesType,
	visibilitySelectorType,
} from "../../../../common/types";
import { CriterionLabel } from "../common/CriterionLabel";
import { SearchCriterion } from "../common/SearchCriterion";

export function DropdownCriterion({
	choices,
	criterionKey,
	label,
	prefix,
	quickSearch = false,
	requiredRoles,
	visibilitySelector,
	...props
}) {
	const { currentInput, onInputChange } = useFormComponentState(
		criterionKey,
		"",
	);
	const dropdownToggle = currentInput
		? choices.find((item) => item.key === currentInput).value
		: "Any";
	const dropdownItems = choices.map((choice, index) => ({
		key: index,
		value: choice.value,
	}));

	const handleDropdownItemClick = (id, name) => {
		const key = choices.find((item) => item.value === name).key;
		onInputChange(key);
	};

	return (
		<SearchCriterion
			label={label}
			prefix={prefix}
			quickSearch={quickSearch}
			requiredRoles={requiredRoles}
			visibilitySelector={visibilitySelector}
		>
			<div className="criterion dropdown single-choice-dropdown">
				{label && <CriterionLabel label={label} prefix={prefix} />}

				<Dropdown
					dropdownKey={criterionKey}
					dropdownItems={dropdownItems}
					dropdownToggle={dropdownToggle}
					label={label}
					onDropdownItemClick={handleDropdownItemClick}
					preselected={!!currentInput}
					quickSearch={quickSearch}
					{...props}
				/>
			</div>
		</SearchCriterion>
	);
}

DropdownCriterion.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	label: PropTypes.string,
	quickSearch: PropTypes.bool,
	requiredRoles: requiredRolesType,
	choices: dropdownCriterionChoicesType.isRequired,
	prefix: PropTypes.string,
	visibilitySelector: visibilitySelectorType,
};
