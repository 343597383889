import Immutable from "immutable";
import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWindowSize } from "./actions";
import { getLayout } from "./selectors";

export const useWindowSize = () => {
	const dispatch = useDispatch();
	const windowSize = useSelector(getLayout);

	const handleSize = () => {
		const newWindowSize = getSize();

		if (!windowSize.equals(Immutable.fromJS(newWindowSize))) {
			dispatch(setWindowSize(newWindowSize));
		}
	};

	useEffect(() => {
		dispatch(setWindowSize(getSize()));
	}, []);

	useLayoutEffect(() => {
		window.addEventListener("resize", handleSize);
		return () => window.removeEventListener("resize", handleSize);
	}, [windowSize]);
};

export function getSize() {
	return {
		isMobile: widthInRange(0, 768),
		isTablet: widthInRange(768, 1200),
	};
}

const widthInRange = (low, high) => {
	const width = window.innerWidth;
	return width >= low && width < high;
};
