import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import useDropdownPosition from "../../../search/criteria/components/useDropdownPosition";
import { getCriterionModalId } from "../../../search/criteria/utils";
import { useAccessWindowSize } from "../../layout/useAccessWindowSize";
import { dropdownItemsType } from "../../types";
import { ActionsMenu } from "../ActionsMenu";
import { ConditionalWrapper } from "../ConditionalWrapper";
import { Loader } from "../Loader";
import useModal from "../modals/useModal";
import { getDropdownClasses } from "./getDropdownClasses";
import { useDropdown } from "./useDropdown";
import { useScrollToSelected } from "./useScrollToSelected";
import useTrackDropdownKey from "./useTrackDropdownKey";

export function Dropdown({
	activeItem,
	additionalClasses,
	closeOnClickOutside,
	dropdownKey,
	disabled = false,
	disableMobile = false,
	dropdownToggle,
	dropdownItems = [],
	dropdownItemsEmptyText,
	label = "",
	loading,
	onDropdownItemClick,
	preselected = false,
	quickSearch = false,
	scrollToView = false,
}) {
	const [prevToggle, setPrevToggle] = useState(dropdownToggle);
	const [toggle, setToggle] = useState(dropdownToggle);
	const {
		dropdownRef,
		dropdownIsOpened,
		onDropdownToggle,
		onDropdownItemWasSelected,
	} = useDropdown(closeOnClickOutside);
	let { isMobile } = useAccessWindowSize();
	if (disableMobile) {
		isMobile = false;
	}
	const { onOpenModal, onCloseModal } = useModal();
	const { isDropdownBelowViewport, isDropdownHidden } = useDropdownPosition(
		dropdownRef,
		dropdownIsOpened,
		quickSearch,
	);
	const { dropdownClasses, dropdownToggleClasses, dropdownMenuClasses } =
		getDropdownClasses(
			dropdownItems,
			dropdownIsOpened,
			loading,
			additionalClasses,
			isDropdownBelowViewport,
			isDropdownHidden,
		);
	const listRef = useRef();
	useTrackDropdownKey(dropdownIsOpened, listRef);
	useScrollToSelected(dropdownIsOpened, scrollToView, listRef);
	const modalId = getCriterionModalId(dropdownKey);

	if (dropdownToggle !== prevToggle) {
		setPrevToggle(dropdownToggle);
		setToggle(dropdownToggle);
	}

	const handleDropdownItemClick = (key, value) => {
		onDropdownToggle();
		onDropdownItemWasSelected();
		setToggle(value);
		onDropdownItemClick(key, value);
		onCloseModal(modalId);
	};

	const handleDropdownClick = () => {
		onOpenModal(modalId);
		onDropdownToggle();
	};

	const items = dropdownItems.map((item) => {
		const { key, value } = item;
		return (
			<li key={key}>
				<button
					type="button"
					className={clsx("dropdown-item", {
						active: value === activeItem,
					})}
					data-id={key}
					onClick={() => handleDropdownItemClick(key, value)}
				>
					{value}
				</button>
			</li>
		);
	});

	const dropdownMenu =
		dropdownItemsEmptyText && dropdownItems.length === 0 ? (
			<p className="dropdown-menu-placeholder">
				{dropdownItemsEmptyText}
			</p>
		) : (
			<ul ref={listRef} className="dropdown-menu-items">
				{items}
			</ul>
		);

	return (
		<div
			ref={dropdownRef}
			className={dropdownClasses}
			data-selected={preselected}
		>
			<button
				type="button"
				className={dropdownToggleClasses}
				aria-expanded={dropdownIsOpened}
				onClick={handleDropdownClick}
				disabled={disabled}
			>
				<span>{toggle}</span>
				{loading && <Loader />}
			</button>

			<ConditionalWrapper
				condition={isMobile}
				wrapper={(children) => (
					<ActionsMenu
						id={modalId}
						title={label}
						onClose={onCloseModal}
					>
						{children}
					</ActionsMenu>
				)}
			>
				<div
					className={dropdownMenuClasses}
					data-criterion={true}
					tabIndex={-1}
				>
					{dropdownMenu}
				</div>
			</ConditionalWrapper>
		</div>
	);
}

Dropdown.propTypes = {
	activeItem: PropTypes.string,
	additionalClasses: PropTypes.shape({
		dropdown: PropTypes.string,
		dropdownToggle: PropTypes.string,
	}),
	closeOnClickOutside: PropTypes.bool,
	dropdownKey: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	disableMobile: PropTypes.bool,
	dropdownItems: dropdownItemsType,
	dropdownItemsEmptyText: PropTypes.string,
	dropdownToggle: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	label: PropTypes.string,
	loading: PropTypes.bool,
	onDropdownItemClick: PropTypes.func.isRequired,
	preselected: PropTypes.bool,
	quickSearch: PropTypes.bool,
	scrollToView: PropTypes.bool,
};
