import Immutable from "immutable";
import queryString from "query-string";
import { addToast } from "../components/toasts/actions";
import { TOAST_WRONG_SEARCH_QUERY } from "../components/toasts/constants";
import { clearSearch, resetSearchData } from "./actions";
import { getCurrentHash } from "./selectors";
import {
	clearHash,
	determineCurrentHash,
	getHashState,
	isValidEncoding,
} from "./util";

export default (store, defaultInput = Immutable.Map()) => {
	function handleHashChange() {
		const state = store.getState();
		const hash = determineCurrentHash();
		const previousHash = getCurrentHash(state);
		if (hash !== previousHash) {
			const hashData = getHashState(hash, defaultInput);
			const params = queryString.parse(hash).search;

			if (params && !isValidEncoding(params)) {
				clearHash();
				store.dispatch(addToast(TOAST_WRONG_SEARCH_QUERY));
			}

			if (hashData.isEmpty()) {
				store.dispatch(clearSearch());
			} else {
				store.dispatch(resetSearchData(hashData));
			}
		}
	}

	// Listen to hash changes.
	window.addEventListener("hashchange", handleHashChange, false);

	return (next) => (action) => {
		const hashBeforeAction = getCurrentHash(store.getState());

		const result = next(action);

		const hashAfterAction = getCurrentHash(store.getState());

		if (hashBeforeAction !== hashAfterAction) {
			location.hash = hashAfterAction;
		}

		return result;
	};
};
