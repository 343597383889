export const onContentLoad = (onLoad) => {
    const tabContentSelector = 'tabs-content';
    const profilePageWithSidebar = document.querySelector(`.page--profile #${tabContentSelector}`);

    if (profilePageWithSidebar) {
        const tabsContent = document.getElementById(tabContentSelector);

        const observer = new MutationObserver(mutations => {
            mutations.forEach(() => {
                onLoad();
            });
        });

        observer.observe(tabsContent, {attributeFilter: ['class']});
    } else {
        onLoad();
    }
}