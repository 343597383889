let keyTracker = '';
let keyTimeout = null;
let openDropdown = null;

export const findKeyElement = (choices, startsWith, isReact = false) => Array
    .from(choices || [])
    .find(item => {
        return isReact ?
            item?.innerText?.toLowerCase().startsWith(startsWith) :
            item.children[1].innerText.toLowerCase().startsWith(startsWith);
    });

export const highlightKeyElement = (targetElement, isReact = false) => {
    if (!targetElement) {
        return;
    }
    targetElement.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
    });
    targetElement.children[isReact ? 0 : 1]?.focus();
}

export const debounceKeyTracker = () => {
    clearTimeout(keyTimeout);
    keyTimeout = setTimeout(() => {
        keyTimeout = null;
        keyTracker = '';
    }, 500);
}

function trackDropdownKey(e) {
    const nextSibling = document.activeElement.parentElement?.nextSibling;
    const choices = openDropdown.firstChild.childNodes;
    const key = e.key.toLowerCase();
    const keyIsRepeating = nextSibling?.innerText?.toLowerCase().startsWith(key);
    keyTracker += key;
    let targetElement = keyIsRepeating ? nextSibling : findKeyElement(choices, keyTracker || key);
    if (keyTracker && !targetElement) {
        targetElement = findKeyElement(choices, key);
    }
    highlightKeyElement(targetElement);
    if (keyTracker) {
        debounceKeyTracker();
    }
}

export const initDropdownKeyTracking = (ids) => {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            const isVisible = entry.intersectionRatio > 0;
            if (isVisible) {
                window.addEventListener("keydown", trackDropdownKey);
                openDropdown = entry.target;
                openDropdown.firstChild.childNodes.forEach(item => {
                    item.children[1].tabIndex = -1;
                });
            } else {
                if (keyTimeout) {
                    clearTimeout(keyTimeout);
                }
                keyTimeout = null;
                keyTracker = '';
                window.removeEventListener("keydown", trackDropdownKey);
                openDropdown = null;
            }
        })
    });
    ids.forEach(id => observer.observe(document.getElementById(id)));
}
