export function initCloseToasts(toasts) {
	closeToastsOnClickOutside();
	toasts.forEach((toast) => {
		closeToastOnClick(toast.querySelector(".toast__close-btn"));
		setToastTimeout(toast);
	});
}

function closeToastOnClick(button) {
	button.addEventListener("click", (e) => {
		e.preventDefault();
		button.closest(".toast").remove();
	});
}

function closeToastsOnClickOutside() {
	const toasts = document.getElementById("toasts");

	if (!toasts) {
		return null;
	}

	document.addEventListener("click", () => removeToasts(toasts));

	toasts.addEventListener("click", (e) => {
		e.stopPropagation();
	});
}

function removeToasts(toasts) {
	if (!toasts) {
		return null;
	}

	toasts.remove();
}

function setToastTimeout(toast) {
	if (!toast) {
		return null;
	}

	toast.addEventListener("transitionend", () => toast.remove());

	setTimeout(() => {
		toast.style.opacity = "0";
	}, 3000);
}
