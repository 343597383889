import { sanitizeHTML } from "../../../search/utils";
import { initCloseToasts } from "./closeToasts";
import { initToasts } from "./initToasts";

export function displayToasts(items) {
	const toastsNumber = items.length;

	if (toastsNumber > 0) {
		let toasts = document.getElementById("toasts");

		// If no toasts - create toasts node first
		if (!toasts) {
			toasts = document.createElement("div");
			toasts.classList.add("toasts");
			toasts.setAttribute("id", "toasts");
			document.body.append(toasts);
			initToasts(toasts);
		}

		items.forEach((item) =>
			toasts.insertAdjacentHTML("beforeend", sanitizeHTML(item)),
		);

		const latestToasts = Array.from(
			toasts.querySelectorAll(".toast"),
		).slice(-toastsNumber);
		initCloseToasts(latestToasts);
	}
}

export function successToast(text) {
	return toast("success", "Success", text);
}

export function errorToast(text) {
	return toast("error", "Error", text);
}

function toast(type, title, text) {
	return `<div class='toast toast--${type}'>
            <span class='toast__icon'></span>
            <h6 class='toast__title'>${title}</h6>
            <p>${text}</p>
            <button class='toast__close-btn'></button>
        </div>`;
}
