import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setLocalViewChecked,
	setShouldLocalViewInterceptSearchParameters,
} from "../search/views/actions";
import useSelectView from "../search/views/useSelectView";
import { getCurrentHashData } from "./hash/selectors";
import { useLocalSearchView } from "./useLocalSearchView";

const ACCEPTED_PARAMETERS = ["columns", "sort", "ascending", "selectedViewId"];
export const useLoadLocalSearchView = (namespace) => {
	const dispatch = useDispatch();
	const hashData = useSelector(getCurrentHashData).toJS();
	const { onSelectView } = useSelectView();
	const { getStoredSearchView, storeSearchView } =
		useLocalSearchView(namespace);

	useEffect(() => {
		const storedSearchView = getStoredSearchView();
		if (
			hashData.parameters?.selectedSearchId ||
			hashData.parameters?.selectedViewId ||
			!storedSearchView ||
			isEmpty(storedSearchView) ||
			!areHashAcceptedParametersEmpty()
		) {
			dispatch(setLocalViewChecked(true));
			return;
		}

		dispatch(setShouldLocalViewInterceptSearchParameters(true));
		dispatch(setLocalViewChecked(true));
		const selectedSearchViewId = storedSearchView.selectedViewId;
		if (selectedSearchViewId) {
			onSelectView(selectedSearchViewId, false);
		}
	}, []);

	const handleStoreSearchView = (hashParameters) => {
		const generatedViewParameters = generateViewParameters(hashParameters);
		const storedViewParameters = getStoredSearchView();

		if (
			JSON.stringify(storedViewParameters) !==
				JSON.stringify(generatedViewParameters) &&
			!isEmpty(generatedViewParameters)
		) {
			storeSearchView(generatedViewParameters);
		}
	};

	const areHashAcceptedParametersEmpty = () => {
		if (!Object.hasOwn(hashData, "parameters")) {
			return true;
		}
		return !ACCEPTED_PARAMETERS.some((param) =>
			Object.hasOwn(hashData.parameters, param),
		);
	};

	const generateViewParameters = (hashParameters) => {
		const finalParams = {};
		if (!hashParameters) {
			return finalParams;
		}
		for (const [key, value] of Object.entries(hashParameters)) {
			if (value && ACCEPTED_PARAMETERS.includes(key)) {
				finalParams[key] = value;
			}
		}
		return finalParams;
	};

	return {
		handleStoreSearchView,
	};
};
