import { encodeHash } from "./util";

export function hasHashData(state) {
	const data = getCurrentHashData(state);
	return data.has("parameters") || data.has("input");
}

export function getCurrentHashData(state) {
	return state.get("hash");
}

export function getCurrentHash(state) {
	const hash = getCurrentHashData(state);
	return encodeHash(hash);
}
