import React from "react";
import { Dropdown } from "../../common/components/dropdown/Dropdown";
import useModal from "../../common/components/modals/useModal";
import { namespaceType } from "../../common/types";
import { useLocalSearchView } from "../../common/useLocalSearchView";
import { trim } from "../utils";
import useLoadViews from "./load/useLoadViews";
import useSelectView from "./useSelectView";
import useView from "./useView";

export function SelectView({ namespace }) {
	useLoadViews(namespace);
	const { onSelectView } = useSelectView();
	const { views, loading, current } = useView();
	const { clearModals } = useModal();
	const { clearSearchView } = useLocalSearchView(namespace);

	let dropdownToggle;
	let viewButtons = [];

	if (!loading) {
		const defaultViewName = "Default view";
		dropdownToggle = current ? current.name : defaultViewName;
		viewButtons = views
			.valueSeq()
			.map((view) => ({ key: view.id, value: view.name }))
			.toArray();
		viewButtons.unshift({ key: 0, value: defaultViewName });
	} else {
		dropdownToggle = "Loading...";
	}

	const handleSelectView = (key) => {
		onSelectView(key);
		clearModals();
		if (key === 0) {
			clearSearchView();
		}
	};

	return (
		<div className="flex info--text">
			<p>View</p>

			<Dropdown
				dropdownToggle={trim(dropdownToggle, 20)}
				dropdownItems={viewButtons}
				activeItem={dropdownToggle}
				onDropdownItemClick={handleSelectView}
				disabled={loading}
				additionalClasses={{
					dropdown: "dropdown--light dropdown--right",
				}}
				dropdownKey={`${namespace}_select_view`}
			/>
		</div>
	);
}

SelectView.propTypes = {
	namespace: namespaceType.isRequired,
};
