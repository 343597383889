import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Dropdown } from "../../../../common/components/dropdown/Dropdown";
import { fromToDropdownsType } from "../../../../common/types";
import { generateDropdownItems, onYearClick } from "../forecast/dropdowns";

export function FromToDropdowns({
	apiData,
	currentInput,
	onInputChange,
	dateRangeCheck,
	disclaimer,
}) {
	if (!apiData) {
		return null;
	}

	const fromYear = currentInput.get("from_year", "");
	const fromMonth = currentInput.get("from_month", "");
	const toYear = currentInput.get("to_year", "");
	const toMonth = currentInput.get("to_month", "");
	const dropdownItems = generateDropdownItems(
		apiData,
		fromYear,
		toYear,
		true,
	);

	const validationResult = dateRangeCheck(currentInput);
	const error = validationResult.isError() && (
		<span className="error-text">{validationResult.getMessage()}</span>
	);
	const errorClasses = clsx("criterion__section", {
		error: validationResult.isError(),
	});

	const onChange = (key, value) => {
		switch (key) {
			case "from_year":
				onYearClick(
					"from",
					value,
					apiData,
					currentInput,
					onInputChange,
				);
				break;

			case "to_year":
				onYearClick("to", value, apiData, currentInput, onInputChange);
				break;

			default:
				onInputChange(currentInput.set(key, value));
		}
	};

	return (
		<div className={errorClasses}>
			<div className="criterion__section-choices">
				<p>From</p>
				<div className="choices-section-dropdowns">
					<Dropdown
						dropdownItems={dropdownItems.from.years}
						dropdownToggle={fromYear}
						onDropdownItemClick={(id) => onChange("from_year", id)}
						preselected={true}
						dropdownKey="dropdown_range_from_years"
						label="From year"
					/>
					<Dropdown
						dropdownItems={dropdownItems.from.months}
						dropdownToggle={fromMonth}
						onDropdownItemClick={(id) => onChange("from_month", id)}
						preselected={true}
						dropdownKey="dropdown_range_from_months"
						label="From month"
					/>
				</div>
			</div>

			<div className="criterion__section-choices">
				<p>To</p>
				<div className="choices-section-dropdowns">
					<Dropdown
						dropdownItems={dropdownItems.to.years}
						dropdownToggle={toYear}
						onDropdownItemClick={(id) => onChange("to_year", id)}
						preselected={true}
						dropdownKey="dropdown_range_to_years"
						label="To year"
					/>
					<Dropdown
						dropdownItems={dropdownItems.to.months}
						dropdownToggle={toMonth}
						onDropdownItemClick={(id) => onChange("to_month", id)}
						preselected={true}
						dropdownKey="dropdown_range_to_months"
						label="To month"
					/>
				</div>
			</div>

			{error}
			{disclaimer && <span>{disclaimer}</span>}
		</div>
	);
}

FromToDropdowns.propTypes = {
	apiData: PropTypes.object,
	currentInput: fromToDropdownsType.isRequired,
	onInputChange: PropTypes.func.isRequired,
	dateRangeCheck: PropTypes.func.isRequired,
	disclaimer: PropTypes.string,
};
