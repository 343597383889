import { showElement, waitForCookiesBanner } from "../utils";
import { getMessagesMargin } from "./messagesMargin";

export function initToasts(toasts) {
	const notification = document.getElementById("notification");
	const cookiesBanner = document.getElementById("main-cookie-banner");

	setPosition(toasts);

	const observer = new MutationObserver((mutations) => {
		mutations.forEach(() => {
			setPosition(toasts);
		});
	});

	if (notification) {
		observer.observe(notification, { attributeFilter: ["style"] });
	}

	if (!cookiesBanner) {
		waitForCookiesBanner((...args) => onReady(toasts, ...args));
	}

	showElement(toasts, "flex");
}

function onReady(item, height) {
	if (height === 0) {
		return null;
	}

	const banner = document.getElementById("industry-and-job-modal");
	const margin = getMessagesMargin();
	const bannerOffset = banner ? banner.offsetHeight + margin : 0;

	item.style.bottom = height + bannerOffset + margin * 2 + "px";
}

function setPosition(toasts) {
	const notification = document.getElementById("notification");
	const banner = document.getElementById("industry-and-job-modal");
	const display = notification?.style.display;
	const margin = getMessagesMargin();
	const bannerOffset = banner ? banner.offsetHeight + margin : 0;

	if (banner) {
		toasts.style.bottom = banner.offsetHeight + margin * 2 + "px";
	}

	if (notification && display === "block") {
		const height = notification.offsetHeight;
		const bottomOffset = notification.style.bottom;
		toasts.style.bottom = height + bannerOffset + margin * 2 + "px";

		if (bottomOffset !== "") {
			toasts.style.bottom =
				height + parseInt(bottomOffset, 10) + margin + "px";
		}
	} else {
		waitForCookiesBanner((...args) => onReady(toasts, ...args));
	}
}
